<template>
  <div :class="['partyTopic', { isLarge: isFA == 2 }]">
    <header>
      <div class="top-wrapper">
        <div class="pic">
          <img :src="detail.topicPicture ? detail.topicPicture : ''" alt="" />
        </div>
        <div class="content">
          <div class="detail-title">{{ detail.topicTitle || "" }}</div>
          <div class="detail-middle">
            <!-- <span>{{ detail.praiseCount || "0" }} 次点赞</span> -->
            <span>{{ detail.readCount || "0" }} 次阅读</span>
          </div>
          <div class="broadcaster" v-if="detail.broadcaster">
            <span>发布人：{{ detail.broadcaster || "" }}</span>
          </div>
          <div class="detail-bottom_time">
            <span>{{ detail.createTime }}</span>
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <!-- <div class="tag" v-for="(item, index) in 20" :key="index"> -->
        <div class="tag">
          <span>#</span>&nbsp;
          <span>{{ detail.talentType || "--" }}</span>
        </div>
        <v-h5Mtml :content="detail.topicTitle"></v-h5Mtml>
      </div>
    </header>
    <!-- <div class="column">
      <div class="hot">
        <span>{{ detail.readCount || "0" }}</span>
        <span>热度</span>
      </div>
      <div class="line"></div>
      <div class="like">
        <span>{{ detail.praiseCount || "0" }}</span>
        <span>点赞</span>
      </div>
    </div> -->
    <v-refresh v-model="isLoading" @refresh="onRefresh" class="main">
      <div class="content">
        <div
          class="text-empty"
          v-if="detail.topicContent && detail.topicContent.length === 0"
        >
          暂无文本
        </div>
        <div class="topicContent" v-else v-html="detail.topicContent"></div>
      </div>
      <!-- <div class="comments">
        <header>
          <span>评论</span><span>{{ commentsData.commentCount || "0" }}</span>
        </header>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="comments-main"
            v-for="(comment, index) in commentList"
            :key="index"
          >
            <div class="userPhoto">
              <img
                :src="
                  comment.avatar
                    ? comment.avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="userInfo">
              <div class="info-title">{{ comment.userName }}</div>
              <div class="info-content">
                {{ comment.commentContent }}
              </div>
              <div class="info-reply" v-if="comment.latestReply">
                <div class="info-reply__content">
                  <span class="content-user">{{
                    comment.latestReply.fromUserName
                  }}</span>
                  <span
                    class="content-to-user"
                    v-if="comment.latestReply.toUserName"
                  >
                    回复 @{{ comment.latestReply.toUserName }}</span
                  >
                  <span class="content-colon">：</span>
                  <span class="content-text">
                    {{ comment.latestReply.replyContent }}
                  </span>
                </div>
                <div
                  class="info-reply__btn"
                  v-show="comment.replyCount > 0"
                  @click="toRouteReply(comment)"
                >
                  <span>共 {{ comment.replyCount }} 条回复</span>
                  <img src="./img/icon-arrow.png" alt="" />
                </div>
              </div>
              <div class="info-foot">
                <div class="foot-time">{{ comment.createTime }}</div>
                <div class="foot-wrap">
                  <div class="iconText" @click="toComments(comment)">
                    <div class="icon">
                      <img src="./img/icon-msg.png" />
                    </div>
                  </div>
                  <div class="iconText" @click="toClickLike(comment)">
                    <div class="icon icon-up">
                      <img
                        v-if="!comment.hasPraised"
                        src="./img/icon-like.png"
                      />
                      <img v-else src="./img/icon-like-red.png" alt="" />
                    </div>
                    <div class="text">
                      <span :class="{ hasPraised: comment.hasPraised }">{{
                        comment.praiseCount
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div> -->
    </v-refresh>
    <!-- <footer>
      <div class="footer-wrap">
        <div class="hot" @click="toClickLike(null)">
          <img v-if="!detail.hasPraised" src="./img/icon-like.png" />
          <img v-else src="./img/icon-like-red.png" alt="" />
          <span>点赞</span>
        </div>
        <div class="line"></div>
        <div class="comment" @click="toComments(null)">
          <img src="./img/icon-msg.png" />
          <span>评论</span>
        </div>
      </div>
    </footer> -->
    <!-- <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend"
    ></v-comments> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicInfoByIdURL,
  cancelPraiseURL,
  addPraiseURL,
  getTopicCommentList,
  addCommentURL,
  getCountUrl,
} from "./api";
// import { vComments } from "@/components/control/index";
import { gloabalCount } from "@/utils/common.js";
export default {
  name: "broadcastDetail",
  components: {},
  data() {
    return {
      id: "", // 记录详情页id
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      curPage: 1,
      pageSize: 20,
      detail: {},
      commentList: [],
      commentsData: {},
      isShowComments: false,
      curReply: null, // 用于记录回复的哪一条评论
      text: "",
      isFA: 1,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    const { id } = this.$route.query;
    if (localStorage.getItem("isFA")) {
      this.isFA = localStorage.getItem("isFA");
    }
    this.id = id;
    await this.getDetail();
    // this.onLoad();
    gloabalCount(this.id, 22, 1);
    this.getCount();
  },
  mounted() {},
  methods: {
    async getCount() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(getCountUrl, { params });
    },
    // 获取详情
    async getDetail() {
      let res = await this.$axios.get(getTopicInfoByIdURL, {
        params: {
          id: this.id,
          userId: this.userId,
        },
      });
      if (res.code === 200 && res.data) {
        this.detail = res.data;
        return;
      } else {
        this.$toast(res.msg || "操作失败，请稍后重试！");
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicCommentList, {
          params: {
            topicId: this.id,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            this.commentsData = res.data;
            if (this.curPage === 1) {
              this.commentList = res.data.records;
            } else {
              const list = this.commentList.map((v) => v.commentId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.commentId) || this.commentList.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 点赞和取消点赞
    toClickLike(data) {
      // item 存在则是评论点赞，反之则是话题点赞
      const item = data || this.detail;
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: data ? item.commentId : item.id, // commentId 评论主键id
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res, "----");
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击评论图标跳出评论控件
    toComments(item) {
      this.curReply = item;
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    // 接受评论组件发送过来的信息
    acceptSend(val) {
      if (!(val.trim && val.trim())) {
        this.$toast({ message: "请输入内容", duration: 1500 });
        return;
      }
      const item = this.curReply;
      let params = {};
      if (item) {
        // 如果存在则为回复
        params = {
          topicId: item.topicId, // 话题id
          commentId: item.commentId, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 1, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
        };
      } else {
        // 反之则为评论
        console.log(this.id);
        params = {
          topicId: this.id, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
        };
      }
      // 1. 调接口
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          ...params,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
          skipImageScan: "skip",
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            // 4. 调评论接口
            this.onRefresh();
            // item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 跳转回复页面
    toRouteReply(item) {
      this.$router.push({
        name: "replyDetail",
        query: {
          title: `${item.replyCount}条回复`,
          detail: JSON.stringify(item),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.partyTopic {
  min-height: 100vh;
  width: 100%;

  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  // overflow: hidden;
  > header {
    box-sizing: border-box;

    background-size: 100% 100%;
    height: 256px;
    // width: 100%;

    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    .top-wrapper {
      border-radius: 40px 40px 0 0;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 32px;
      height: 100%;
      .pic {
        width: 190px;
        height: 190px;
        border-radius: 16px;
        margin-right: 24px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        flex: 1;
        .detail-title {
          width: 100%;

          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          font-size: 36px;
          font-weight: 600;
          color: #323334;
          line-height: 52px;
          margin-top: 8px;
        }
        .detail-middle {
          margin: 8px 0 8px;
          font-size: 24px;
          font-weight: 400;
          color: #646668;
          line-height: 36px;
          > span {
            margin-right: 32px;
          }
        }
        .broadcaster {
          color: #646668;
          font-size: 24px;
          margin: 0 0 4px 0;
        }
        .detail-bottom {
          display: flex;
          font-size: 24px;
          margin-bottom: 4px;
          .detail-bottom_tag {
            background: #fff3eb;
            border-radius: 8px;
            border: 2px solid #ffc7a4;
            padding: 4px 12px;
            margin-right: 32px;
            overflow: hidden;
            > .text {
              font-size: 24px;
              font-weight: 400;
              color: #fe6f16;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .detail-bottom_time {
          font-size: 24px;
          color: #646668;
          display: flex;
          align-items: center;
        }
      }
    }
    .bottom-wrapper {
      margin-top: 32px;
      display: flex;
      font-size: 24px;
      overflow: auto;
      white-space: nowrap;
      .tag {
        margin-right: 32px;
        background: rgba(255, 255, 255, 0.17);
        border-radius: 8px;
        border: 1px solid #ffffff;
        padding: 6px 12px;
        line-height: 32px;
        background: #ffffff34;
      }
    }
  }
  .column {
    display: flex;
    padding: 32px 0 16px;
    box-shadow: inset 0px -1px 0px 0px rgba(230, 230, 230, 1);
    align-items: center;
    .line {
      width: 2px;
      height: 40px;
      background: #e6e6e6;
    }
    .hot,
    .like {
      width: calc(50% - 1px);
      span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
      span:nth-of-type(1) {
        font-size: 40px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
      }
      span:nth-of-type(2) {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #aaaaaa;
        line-height: 32px;
      }
    }
  }
  .main {
    box-sizing: border-box;
    padding: 0 32px 128px 32px;
    margin-top: 32px;
    background: #fff;
    // margin-bottom: 96px;
    // height: calc(100% - 426px);
    // overflow-x: hidden;
    // overflow: auto;
    // ::v-deep .van-pull-refresh__track {
    //   height: auto;
    // }
    .content {
      width: 100%;
      overflow-x: hidden;
      box-sizing: border-box;
      margin-bottom: 80px;
      font-size: 32px;
      line-height: 1.5;
      color: #333333;
      word-wrap: break-word;
      > .text-empty {
        height: 96px;
        line-height: 96px;
        background: #eeeeee;
        text-align: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      ::v-deep img {
        width: 100%;
      }
    }
    .comments {
      > header {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #1a1c34;
        line-height: 44px;
        > span {
          &:nth-of-type(1) {
            margin-right: 8px;
          }
          // &:nth-of-type(2) {
          // }
        }
      }
      .comments-main {
        display: flex;
        margin-top: 32px;
        .userPhoto {
          height: 72px;
          width: 72px;
          min-width: 72px;
          overflow: hidden;
          border-radius: 50%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .userInfo {
          word-wrap: break-word;
          flex: 1;
          margin-left: 16px;
          max-width: calc(100% - 88px);
          .info-title {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info-content {
            font-size: 32px;
            margin-top: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }
          .info-reply {
            background: #f7f7f7;
            line-height: 40px;
            padding: 24px;
            margin-top: 24px;
            word-wrap: break-word;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            .info-reply__content {
              .content-user,
              .content-colon {
                font-weight: bold;
                color: #333333;
              }
              .content-to-user {
                color: blur;
              }
              .content-text {
                font-weight: 400;
                color: #666666;
                vertical-align: middle;
              }
            }
            .info-reply__btn {
              margin-top: 8px;
              white-space: nowrap;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;
              > span {
                font-weight: bold;
                color: #333333;
              }
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          .info-foot {
            margin-top: 24px;
            line-height: 32px;
            display: flex;
            .foot-time {
              color: #a8a8a8;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a8a8a8;
            }
            .foot-wrap {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              > .iconText {
                display: flex;
                // margin-right: 32px;
                color: #a8a8a8;
                align-items: center;
                &:not(:last-child) {
                  margin-right: 32px;
                }
                .icon {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  &.icon-up {
                    > img {
                      margin-top: -2px;
                    }
                  }
                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .text {
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  height: 32px;
                  span {
                    &.hasPraised {
                      color: #ff853a;
                    }
                    // color: #ff853a;
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // display: flex;
    // padding: 24px 0 24px;
    box-shadow: inset 0px 2px 0px 0px rgba(230, 230, 230, 1);
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 24px 0 24px;
      .line {
        width: 2px;
        height: 40px;
        background: #e6e6e6;
      }
      .hot,
      .comment {
        width: calc(50% - 1px);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 48px;
          height: 48px;
          margin-right: 16px;
        }
        span {
          line-height: 1;
          text-align: center;
          font-size: 32px;
          color: #333333;
          font-family: DINAlternate-Bold, DINAlternate;
        }
      }
    }
  }
}
.isLarge {
  header {
    height: 284px;
    .top-wrapper {
      .pic {
        width: 210px;
        height: 210px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
      > .content {
        .detail-title {
          font-size: 40px;
        }
        .detail-middle {
          font-size: 30px;
        }

        .detail-bottom_time {
          font-size: 30px;
        }
      }
      .broadcaster {
        font-size: 30px !important;
      }
    }
    .bottom-wrapper {
      font-size: 34px;
    }
  }
  .column {
    .hot,
    .like {
      span:nth-of-type(1) {
        font-size: 46px;
      }
      span:nth-of-type(2) {
        font-size: 28px;
      }
    }
  }
  .main {
    .content {
      .topicContent {
        font-size: 40px !important;
        div,
        span,
        p {
          font-size: 40px !important;
        }
      }
    }
  }
}
</style>
